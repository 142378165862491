import React, { useEffect } from 'react';
import {
  FirstColumn,
  SecondColumn,
  TwoColumnLayout,
} from '../components/layout-body';
import SearchableTripsList from '../components/SearchableTripsList/SearchableTripsList';
import TripFiltersFeature from '../features/TripFiltersFeature/TripFiltersFeature';
import useTripFilters from '../features/TripFiltersFeature/use-trip-filters';
import useLoloMember from '../hooks/use-lolo-member';

const Dashboard: React.FC = (): React.ReactElement => {
  const { handleTripFiltersChange, tripFilters } = useTripFilters();

  // const { isLoading, member } = useLoloMember();
  // useEffect(() => {
  //   // If it's done loading, and there is no member id, then redirect to no-permissions page
  //   if (!isLoading && !member?.id) {
  //     window.document.location.href = '/no-permissions';
  //   }
  // }, [isLoading]);

  return (
    <TwoColumnLayout protect>
      <FirstColumn sticky>
        <TripFiltersFeature
          hideHighestStatusFilter
          onFilterChange={handleTripFiltersChange}
        />
      </FirstColumn>

      <SecondColumn>
        <SearchableTripsList
          sourceUrl="Dashboard"
          searchTripFilters={tripFilters}
        />
      </SecondColumn>
    </TwoColumnLayout>
  );
};

export default Dashboard;
